@import "Config/ThemeVariables.less";
@primary-color: #1890ff;
@error-color: #ff4d4f;
@text-color: rgba(0, 0, 0, 0.85);
@border-radius-base: 4px;

.notification-page-action-wrapper {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  gap: 14px;
}

.delete-button-text {
  color: white
}

.ant-row {
  &+.ant-row {
    padding: 0 12px;
  }
}

.notification-table {
  margin-top: 20px;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .search-input {
    width: 240px;

    .anticon {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .header-text {
    color: @text-color;
    font-size: 14px;
  }
}

.preview-text {
  color: rgb(4, 135, 249);
}

.read-status {
  .read-check {
    color: #52c41a;
    font-weight: bold;
  }
}


.notification-container {
  width: 460px;
  max-width: 100vw;

  @media (max-width: 480px) {
    width: 100%;
  }

  .notification-header {
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .notification-list {
    max-height: 40vh;
    overflow-y: auto;
  }

  .notification-item {
    padding: 12px !important;

    &:last-child {
      border-bottom: none;
    }
  }

  .notification-content {
    width: 100%;

    .notification-body {
      flex: 1;
      min-width: 0;
    }

    .icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .notification-title-row {
    margin-bottom: 8px;

    .date {
      font-size: 12px;
    }
  }

  .description {
    display: block;
    color: rgba(0, 0, 0, 0.65);
    margin: 4px 0 8px 0;
  }

  .view-details-btn {
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    gap: 4px;

    .anticon {
      font-size: 12px;
    }
  }
}